.popup-overlay {
    z-index: 200;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.popup {
    font-family: Arial, sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popup-content {
    text-align: center;
}

.popup .close {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}