h2 > .citation {
  font-style: italic;
  font-size: 0.6em;
}

.ddd {
  height: 48px;
  font-size: 13px;
  font-size: 0.8125rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

div[class^="section-state--"] {
  padding: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  text-align: center;
}

.section-state--NEW {
  background-color: #ED7D31 !important;
  color: white;
}

.section-state--PUBLISHED {
  background-color: #33CC33 !important;
  color: white;
}

.section-state--PENDING_ARCHIVE {
  background-color: #6F906F !important;
  color: white;
}

.section-state--ARCHIVED {
  background-color: #7F7F7F !important;
  color: white;
}

.section-state--READY {
  background-color: #219FA8 !important;
  color: white;
}

.section-state--CANCELED {
  background-color: #FF0000 !important;
  color: white;
}

.section-state--EDITED {
  background-color: #FFFF00 !important;
}
