@import '../../../../../libs/lumx/dist/scss/bourbon.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_mixins.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../../../libs/lumx/dist/scss/modules/_icon.scss';

$protocols: ('LS': $purple-300, 'PE': $amber-300, 'RP': $light-green-300, 'RI': $brown-300);

@mixin icon-size-svg($size, $type) {
  height: map-get($sizes, $size) !important;
  width: map-get($sizes, $size) !important;
}

// Icon protocol color
@each $key, $color in $protocols {
  .protocol--#{$key},
  %protocol--#{$key} {
    &.icon--circled,
    &%icon--circled,
    &.btn--raised,
    &%btn--raised {
      color: $white-1;
      background-color: $color !important;
      &[disabled] {
        background-color: $grey-300 !important;
      }
    }

    &.icon--flat,
    &%icon--flat,
    &.btn--flat,
    &%btn--flat {
      color: $color !important;
    }
  }
}

.tx-icon-container {
  fill: currentColor;
  display: inline-block;
  min-width: 24px;

  // Icon sizes
  @each $key, $size in $sizes {
    .icon--#{$key},
    %icon--#{$key} {
      &.icon--circled,
      &%icon--circled {
          @include icon-size-svg($key, 'circled');
      }

      &.icon--flat,
      &%icon--flat {
          @include icon-size-svg($key, 'flat');
      }
    }
  }
}
