@import '../../../../../libs/lumx/core/scss/settings/_colors.scss';

.tx-group-input {
  min-width: 100px;
  max-width: 100px;
  text-align: center;
  cursor: pointer;

  .icon {
    color: #ffffff;
    background-color: $grey-500;
  }

  &:hover .icon {
    background-color: $green-300;
  }

  &--selected .icon,
  &--selected:hover .icon {
    background-color: $green-500;
  }
}