@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';

.profile {
  min-width: 800px;

  @include media-query(palm) {
    min-width: 0;
  }
  @include media-query(lap) {
    min-width: 0;
  }
}
