// XCA: pour importer materialdesignicons de npm

/* MaterialDesignIcons.com */
// @import "variables";

$mdi-filename:         "materialdesignicons";
$mdi-font-name:        "Material Design Icons" !default;
$mdi-font-family:      "materialdesignicons";
$mdi-font-weight:      "regular";
$mdi-font-path:        "../fonts" !default;
$mdi-font-size-base:   24px !default;
$mdi-css-prefix:       mdi !default;
$mdi-version:          "1.4.57" !default;

@import "~mdi/scss/path";
@import "~mdi/scss/core";
@import "~mdi/scss/icons";
@import "~mdi/scss/extras";
