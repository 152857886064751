@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../base/variables.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';

.settings-form {
  min-width: 600px;

  @include media-query(palm) {
    min-width: unset;
  }
}
