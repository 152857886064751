
@import '../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../libs/lumx/dist/scss/tools/_mixins.scss';

// Data table base styles
.condensed-data-table {
    width: 100%;
    margin: 0;
    table-layout: fixed;

    th, td {
        padding-left: $base-spacing-unit * 3;
        padding-right: $base-spacing-unit * 3;
        border-bottom: 1px solid #e0e0e0;

        &:first-child {
            padding-left: 0;
            padding-right: $base-spacing-unit * 3;
        }

        &:last-child {
            padding-left: $base-spacing-unit * 3;
            padding-right: $base-spacing-unit * 3;
        }
    }

    th {
        height: 56px;
        @include font-size(12px);
        font-weight: 400;
        color: $black-2;
        text-align: left;
        white-space: nowrap;

        i {
            margin-right: $base-spacing-unit / 2;
        }
    }

    td {
        height: 48px;
        @include font-size(13px);
        padding-top: $base-spacing-unit / 2;
        padding-bottom: $base-spacing-unit / 2;
    }

    tr:last-child td {
        border-bottom: none;
    }
}