textarea.citation {
  height: 100%;
  font-style: italic;
  font-size: 0.7em;
}

.tooltip__label {
  max-width: 500px;
  white-space: pre-line;
}
