.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  &__item {
    display: inline-block;
    max-width: 600px;
  }

  &__logo {
    height: 62px;
    display: inline-block;
  }

  &__profile {
    line-height: 30px !important;
  }
}

.load-animate {
  animation: load-animate 2s infinite linear;
}

@keyframes load-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}