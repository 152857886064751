
.signin-form {
  height: 100%;
  min-height: 521px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__inputs {
    flex-grow: 1;

    width: 70%;
    margin-left: 15%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    .button {
      width: 100%;
      text-align: left;
    }
  }

  &__bottom {
    margin-top: auto;
    &__version {
      text-align: center;
    }
    &__buttons {
      .bottom-button {
        width: 100%;
      }
    }
  }
  &__logo_container {
    text-align: center;
  }
  &__logo {
    height: 102px;
    width: 118px;
    display: inline-block;
  }
}
