@import '../../../libs/lumx/dist/scss/settings/_colors.scss';

$color-content-background: #e7e7e7;

$color-sidebar-background: #03abff;
$color-sidebar-text: #ffffff;

$color-taxon-patrimonial: $orange-300;
$color-taxon-protege: $red-500;
$color-taxon-protege-alt: $red-800;
$color-taxon-exotique: $pink-A200;
$color-taxon-exotique-alt: $pink-A100;

$field-record-on-the-fly-buttons-height: 55px;

$header-height: 64px;
$header-height-portable: 56px;

$desk-width: 1024px;

///*------------------------------------*\
//    #SETTINGS-RESPONSIVE
//\*------------------------------------*/

// Hold our breakpoint aliases and conditions in a list.
//
// These can be invoked later on via the `media-query()` mixin found in
// `_tools.responsive`.

$breakpoints: (
    "palm"          "screen and (max-width: 601px)",
    "lap"           "screen and (min-width: 602px) and (max-width: 962px)",
    "lap-and-up"    "screen and (min-width: 602px)",
    "portable"      "screen and (max-width: 1023px)",
    "desk"          "screen and (min-width: 1024px)",
    "desk-wide"     "screen and (min-width: 1280px)",
    "full-hd"       "screen and (min-width: 1920px)",
    "retina"        "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)"
)
