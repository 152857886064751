@import '../../../../../libs/lumx/dist/scss/bourbon.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_mixins.scss';
@import '../../../../../libs/lumx/dist/scss/tools/_responsive.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_colors.scss';
@import '../../../../../libs/lumx/dist/scss/settings/_defaults.scss';
@import '../../../../../libs/lumx/dist/scss/modules/_icon.scss';

.number-input__container {
  position: relative;
}

.number-input__input-wrapper {
    position: relative;

    &:hover {
        .number-input__clear {
            opacity: 1;
        }
    }
}

.number-input__buttons-wrapper {
  margin-left: 8px;
}

.number-input__clear {
    display: block;
    @include position(absolute, null ($base-spacing-unit * 2)  $base-spacing-unit null);
    cursor: pointer;
    line-height: $base-spacing-unit * 4;
    @include transition-property(opacity, color);
    @include transition-duration(.2s);

    &:hover {
        color: $red-500;
    }
}
