.data-table th.th-type {
  width: 10px;
  padding-left: 0px;
  padding-right: 24px;
}

.th-year {
  width: 85px;
}

.th-citation {
  width: auto;
}

.th-taxonomicGroup {
  width: 160px;
}

.th-status {
  width: 160px;
}

.th-terrUnit {
  width: 160px;
}

.data-table td.type {
    padding-left: 5px;
    padding-right: 35px;
}

.year {
 font-weight: bold;
}

.citation > a {
 font-style: italic;
 text-decoration: none;
}

.taxonomicGroup {

}

.status {

}

.terrUnit {

}

.chapter-box {
    height: auto;
    flex: 1 1 auto;
}

.chapter-box svg {
    display: block;
    overflow: visible;
    width: 100%;
    height: 100%;
}

.DER--OTHERS {
  background-color: green;
}

.DER--EEE {
  background-color: red;
}

.fill--REGULATION {
  fill: #D51C29;;
}

.fill--OTHERS {
  fill: #fcbf18;
}

.fill--EEE {
    fill: #4DB4D7;
}

.fill--TAXA_ECOLOGY {
    fill: #00a300;
}

/*SVG ICON SYSTEM*/
.legend_icon {
    display: inline-flex;
    align-self: center;
}

.legend_icon svg, .legend_icon img {
    height: 1em;
    width: 1em;
    fill: currentColor;
}

.legend_icon.baseline svg, .legend_icon img {
    top: .2em;
    position: relative;
}

.redColor {
  background-color: #ff5733;
}

.greyColor {
  background-color: #75706f;
}

.whiteColor {
  background-color: #FFFFFF;
}
