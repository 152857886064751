.publication-advanced-search-form {
  .publication-advanced-search-form__query {
    max-width: 800px;
    margin: auto;

  }
}

.publication-advanced-search-form .align-button {
  align-self: center;
  height: 30px;

}

.publication-advanced-search-form__toolbar {
  height: 200px;
  text-overflow: ellipsis;
}

.list-row:not(.list-row--multi-line) .list-row__primary {
  width: 35px;
  padding: 2px 0;
}

.list-row__content {
  padding: 2px 0;
}

#searchRadio > lx-radio-group > div.radio-group {
  display: inline-flex;
}

#REGULATION > lx-checkbox > div.checkbox > label:after {
  color: #D51C29;
}

#OTHERS > lx-checkbox > div.checkbox > label:after {
  color: #fcbf18;
}

#TAXA_ECOLOGY > lx-checkbox > div.checkbox > label:after {
  color: #00a300;
}

#EEE > lx-checkbox > div.checkbox > label:after {
  color: #4DB4D7;
}

label.radio-button__label {
  padding-right: 10px;
}

div[name^="publicationSearchBy"].lx-select {
    padding-top: 0px;
    padding-bottom: 0px;
}

.grptx {
  text-overflow: ellipsis;
}
